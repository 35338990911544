//Libraries
import React, {useState, useEffect} from 'react'

//Component
import { Row, Button, Alert } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom'

//CSS
import './Login.css'

const Login = () => {

    const [password, setPassword] = useState(String);
    const alertMessage = "Wrong Password. Try again !"
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const defaultPw = "ILoveYou,May"


    const login = () => {
        if(password === defaultPw){
            navigate("/home")
        }
        else{
            setShow(true)
        }
    }
    

  return (
    <div className='login_container'>
        <Row>
            <h1 className='login_title'>
               Hey May....
            </h1>
        </Row>
        <Row>
        <Form>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label>Enter the password given by Mg Mg</Form.Label>
                    <Form.Control 
                        className='login-pw-box'
                        type="password" 
                        placeholder="Password"
                        onChange={(e) => {setPassword(e.target.value);
                        }} />
                </Form.Group>
                <Button className='login-btn' onClick={() => login()}>
                    Login
                </Button>
            </Form>
        </Row>

        <Alert show={show} variant="danger" style={{ marginTop: 10}} onClose={() => setShow(false)} dismissible>
            {alertMessage}
        </Alert>
    
    </div>
  )
}
export default Login;
